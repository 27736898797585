import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { SimpleInput } from '../../../../commonComponents/Input/Input';

const ratingExams = [
  'E4 Reg',
  'E4 Sub',
  'E4 Res',
  'E5 Reg',
  'E5 Sub',
  'E5 Res',
  'E6 Reg',
  'E6 Sub',
  'E6 Res',
  'E7 Reg',
  'E7 Sub',
  'E7 Res',
];

export default class RatingExamSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.selectAllRatingExam(ratingExams);
  }

  render() {
    return (
      <Row>
        {ratingExams.map((ratingExam) => (
          <Col
            xs={3}
            key={ratingExam}
            className="col-12 col-sm-6 col-md-4 px-0"
          >
            <SimpleInput
              description={`${ratingExam}`}
              key={`${ratingExam}`}
              type="checkbox"
              initialValue
              value={!!this.props.selectedRatingExam.includes(ratingExam)}
              onChange={() => {
                this.props.changeRatingExamSelected(ratingExam);
              }}
            />
          </Col>
        ))}
      </Row>
    );
  }
}

RatingExamSelector.propTypes = {
  selectAllRatingExam: PropTypes.func.isRequired,
  changeRatingExamSelected: PropTypes.func.isRequired,
  selectedRatingExam: PropTypes.array.isRequired,
};
