import './QuestionDetails.css';

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button } from 'reactstrap';
import _ from 'underscore';

import DropDown from '../../../../commonComponents/DropDown/DropDown';
import ConfirmDeletion from '../../../../commonComponents/Modals/ConfirmDeletion/ConfirmDeletion';
import SimpleConfirmation from '../../../../commonComponents/Modals/SimpleConfirmation/SimpleConfirmation';
import Spinner from '../../../../commonComponents/Spinner/Spinner';
import TextLink from '../../../../commonComponents/TextLink/TextLink';
import WYSIWYG from '../../../../commonComponents/WYSIWYG/WYSIWYG';
import ConvertHtmlToPlainText from '../../../../utilities/ConvertHtmlToPlainText';
import nonModifiedQuestionOrAnswer from '../../../../utilities/nonModifiedQuestionOrAnswer';
import Footer from '../../../Footer';
import { AI_TASK_STATUS } from '../Header/constants/constants';
import QuestionHistoryModal from '../QuestionHistory/ModalQuestionHistory';
import UploadInput from '../UploadInput/UploadInput';

const NotificationSystem = require('react-notification-system');

const orderOptions = [
  { value: 1, text: 'A' },
  { value: 2, text: 'B' },
  { value: 3, text: 'C' },
  { value: 4, text: 'D' },
];

export default class QuestionDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      question: this.getCopyOfObject(this.props.defaultQuestion),
      isFlashCardMode: false,
      questionData: {},
      substantialChangesQuestion: false,
      substantialChangesAnswer: false,
      customPrompt: '',
      addCustom: false,
      customText: '',
      loadCustomPrompts: false,
      createCustom: false,
      value: false,
      userCustomPrompts: this.props.userCustomPrompts,
      customPromptSelect: '',
      errorCustom: false,
      creatingAiPrompt: false,
    };

    this.change;
    this._notificationSystem = null;
  }

  componentWillMount() {
    let hasGPTPermissions = false;

    if (this.props.user && this.props.user.roles) {
      hasGPTPermissions = !!(
        (Array.isArray(this.props.user.roles) &&
          this.props.user.roles.some(
            (role) => role?.name === 'Create GPT questions',
          )) ||
        this.props.user.roles?.name === 'Create GPT questions' ||
        this.props.user.isSuperAdmin
      );
    }

    if (this.props.question && this.props.question.id) {
      let question = this.getCopyOfObject(this.props.question);
      question = this.removeEscapeCharactersFromQuestion(question);
      this.setState({
        question: this.getCopyOfObject(this.props.question),
        isFlashCardMode: !this.props.question.multipleOptionMode,
      });
    }
    if (this.props.isRefreshForm) {
      this.refreshForm();
    }

    if (
      this.props.assignment &&
      this.props.assignment.chapter &&
      hasGPTPermissions
    ) {
      this.props.getUserCustomPrompt(this.props.assignment.chapter.id);
      this.setState({ loadCustomPrompts: true });
    }
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
    this.initializeListener();
  }

  componentDidUpdate(prevProps, prevState) {
    const hasGPTPermissions =
      this.props.user &&
      this.props.user.roles &&
      Array.isArray(this.props.user.roles)
        ? this.props.user.roles
            .map((role) => role.name)
            .includes('Create GPT questions') || this.props.user.isSuperAdmin
        : this.props.user.roles.name === 'Create GPT questions' ||
          this.props.user.isSuperAdmin;

    if (
      prevProps.userCustomPrompts &&
      this.props.userCustomPrompts &&
      hasGPTPermissions
    ) {
      if (
        prevProps.userCustomPrompts.length !==
          this.props.userCustomPrompts.length &&
        this.props.chapter &&
        this.props.chapter.id
      ) {
        this.props.getUserCustomPrompt(this.props.chapter.id);
      }
    }

    if (
      (Array.isArray(this.props.userCustomPrompts) && !this.state.value) ||
      prevProps.question !== this.props.question
    ) {
      // Check for userCustomPrompts. If create, keep last prompt; if editquestion, keep the created custom.

      let createCustom = this.state.createCustom;
      let customSelect =
        this.state.userCustomPrompts && this.state.userCustomPrompts.length
          ? this.state.userCustomPrompts[0]
          : [];
      let textCustom = this.state.customText;
      let addCustom = this.state.addCustom;

      if (this.props.question) {
        if (
          this.props.question &&
          this.props.question.originalResponse &&
          !this.props.question.originalResponse.adminUser
        ) {
          this.props.setIsCreatingAiQuestion(false);
        }
        if (
          this.props.userCustomPrompts &&
          this.props.userCustomPrompts.length
        ) {
          this.props.userCustomPrompts.forEach((custom) => {
            if (this.props.question.originalResponse) {
              if (
                custom.id === this.props.question.originalResponse.ai_prompt
              ) {
                customSelect = custom;
                textCustom = custom.prompt.replace('USER_PROMPT ', '');
                createCustom = false;
                addCustom = true;
              }
            }
          });
        }
      } else {
        customSelect =
          this.state.userCustomPrompts && this.state.userCustomPrompts.length
            ? this.state.userCustomPrompts[0]
            : [];
        createCustom = false;
        textCustom = '';
        addCustom = false;
      }

      this.setState({
        value: true,
        customText: textCustom,
        createCustom,
        customPromptSelect: customSelect,
        addCustom,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const hasChanges = (question) =>
      !question.defaultQuestion ||
      this.countCharacters(question.text) > 0 ||
      question.notes > 0 ||
      question.answers
        .map((answer) => this.countCharacters(answer.text) > 0)
        .reduce((acc, curr) => acc || curr, false);

    if (nextProps.isRefreshForm) {
      this.refreshForm();
    }
    if (nextProps.isSaveQuestion === this.props.isSaveQuestion) {
      if (nextProps.question) {
        let question = { ...nextProps.question, updateEditorView: true };
        question = this.removeEscapeCharactersFromQuestion(question);
        this.setState({
          question,
          isFlashCardMode: !nextProps.question.multipleOptionMode,
          substantialChangesQuestion: nextProps.question.substantialChanges,
        });

        if (question.answers) {
          const correctAnswer = question.answers.filter(
            (answer) => answer.correct === true,
          );
          this.setState({
            substantialChangesAnswer:
              correctAnswer && correctAnswer[0]
                ? correctAnswer[0].substantialChanges
                : false,
          });
        }
      } else {
        /* if (!this.props.isCreatingAiQuestion &&
          this.countCharacters(this.state.question.text) <= 1000
        ) */
        if (!hasChanges(this.state.question)) {
          this.setState({
            question: this.getCopyOfObject(this.props.defaultQuestion),
            isFlashCardMode: false,
          });
        }
      }
    }

    if (nextProps.isSaveQuestion) {
      this.showConfirmModal();
      this.props.setIsSaveQuestion(); // this sets isSaveQuestion to false
    }
    if (
      (nextProps.question &&
        this.props.question &&
        nextProps.question.id !== this.props.question.id) ||
      (nextProps.question && !this.props.question)
    ) {
      this.setState({
        isFlashCardMode: !nextProps.question.multipleOptionMode,
      });
      this.props.loadPublishedQuestion(null);
    }
  }

  removeEscapeCharacters(text) {
    if (text) {
      return text.split('</p><p>').join('<br>');
    }
  }

  countCharacters(text) {
    const textWithoutPattern = text.split('</p><p>').join('');
    const characterCount = textWithoutPattern.split('<br>').join('').length - 7;
    return characterCount;
  }

  removeEscapeCharactersFromQuestion(question) {
    if (question.text) {
      question.text = this.removeEscapeCharacters(question.text);
    }
    if (question.notes) {
      question.notes = this.removeEscapeCharacters(question.notes);
    }
    if (question.flashQuestionText) {
      question.flashQuestionText = this.removeEscapeCharacters(
        question.flashQuestionText,
      );
    }
    if (question.flashQuestionNotes) {
      question.flashQuestionNotes = this.removeEscapeCharacters(
        question.flashQuestionNotes,
      );
    }
    if (question.answers) {
      question.answers = question.answers.map((ans) => {
        ans.text = this.removeEscapeCharacters(ans.text);
        return ans;
      });
    }

    return question;
  }

  _handleKeyDown = (button) => {
    const selectedButton = document.getElementsByClassName(`${button}`)[0];
    if (selectedButton) {
      selectedButton.click();
      const questionInput = document.getElementsByClassName(
        'TextArea BorderTextArea form-control',
      )[0];
      if (questionInput) {
        questionInput.focus();
      }
    }
    event.preventDefault();
  };

  initializeListener = () => {
    let pressed = true;
    document.addEventListener('keydown', (event) => {
      if (
        pressed &&
        event.ctrlKey &&
        event.shiftKey &&
        event.code === 'Enter'
      ) {
        pressed = false;
        this._handleKeyDown('btn-save-default');
      }
      if (pressed && event.ctrlKey && event.code === 'Enter') {
        pressed = false;
        this._handleKeyDown('btn-save-create');
      }
    });
    document.addEventListener('keyup', () => {
      pressed = true;
    });
  };

  refreshForm = () => {
    this.props.setIsRefreshForm(false);
    this.setState({
      question: this.getCopyOfObject(this.props.defaultQuestion),
      isFlashCardMode: false,
    });
  };

  onChangeFurtherReading = (content) => {
    const question = this.state.question;
    question.flashQuestionNotes = content;
    question.notes = content;
    question.updateEditorView = false;
    this.setState({ question });
  };

  onChangeQuestion = (value) => {
    const newQuestion = this.state.question;
    newQuestion.flashQuestionText = value;
    newQuestion.text = value;
    this.setState({ question: newQuestion });
  };

  onChangeAnswer = (text, answerId) => {
    const newQuestions = this.getCopyOfObject(this.state.question);
    let maxId = 0;
    let aux = 0;
    newQuestions.answers.sort((a, b) =>
      a.id > b.id ? 1 : b.id > a.id ? -1 : 0,
    );

    const correctAnswer = newQuestions.answers.filter((a) => a.correct)[0];
    if (correctAnswer) {
      newQuestions.redBorderA = false;
    }

    newQuestions.answers.forEach((nAnswer) => {
      if (nAnswer) {
        aux = nAnswer.id;
        if (aux > maxId) {
          maxId = aux;
        }
      }
    });
    if (answerId) {
      // the input is an answer

      let plus = 1;
      // check if not have answers
      if (newQuestions.answers.length < 1) {
        text = null;
        const template = {
          id: plus + maxId,
          correct: true,
          order: 0,
          text: '',
        };
        newQuestions.answers.push(template);
        plus++;
        for (let i = 1; i < 4; i++) {
          if (!newQuestions.answers[i]) {
            const answerTemplate = {
              id: plus + maxId,
              correct: false,
              order: 0,
              text: '',
            };
            newQuestions.answers.push(answerTemplate);
            plus++;
          }
        }
      }
      const index = newQuestions.answers.findIndex((x) => x.id === answerId);

      if (
        !correctAnswer ||
        (correctAnswer.text === '' &&
          newQuestions.answers[index].id !== correctAnswer.id)
      ) {
        if (
          (newQuestions.answers[index - 1] &&
            (!newQuestions.answers[index - 1].text ||
              newQuestions.answers[index - 1].text === ' ')) ||
          (!newQuestions.answers[index - 1] &&
            newQuestions.answers[index].correct === false)
        ) {
          switch (index) {
            case 1:
              newQuestions.redBorderB = true;
              break;
            case 2:
              newQuestions.redBorderC = true;
              break;
            case 3:
              newQuestions.redBorderD = true;
              break;
            default:
              break;
          }
        } else {
          newQuestions.redBorderB = false;
          newQuestions.redBorderC = false;
          newQuestions.redBorderD = false;
        }
      }
      newQuestions.answers[index].text = text;
    }
    this.setState({ question: newQuestions });
  };

  onChangePageNumber = (event) => {
    const newQuestions = this.getCopyOfObject(this.state.question);
    newQuestions[event.target.name] = event.target.value;
    this.setState({ question: newQuestions });
  };

  optionIsSelected = (option) =>
    !!(
      this.state.question.answers &&
      this.state.question.answers.find &&
      this.state.question.answers.find(
        (answer) => answer.order === option.value,
      )
    );

  onSelectChange = (id, event) => {
    const newQuestions = this.state.question;
    const index = this.state.question.answers.findIndex((x) => x.id === id);
    newQuestions.answers[index].order = parseInt(event.target.value, 10);
    this.setState({ question: newQuestions });
  };

  onChangeIncludeNotesInSuggestion = (question, value) => {
    this.props.updateIncludeNotesInSuggestion(question.id, value);
    this.setState({
      question: { ...question, includeNotesInSuggestion: value },
    });
  };

  isQuestionDisabled = (question, assignment, chapter) => {
    let isInAssignment = false;
    let isInCurrentAssignment = false;
    if (assignment && assignment.disabledQuestions) {
      isInAssignment = _.pluck(assignment.disabledQuestions, 'id').includes(
        question.id,
      );
    }
    if (
      !assignment &&
      chapter &&
      chapter.assignments &&
      chapter.assignments.length
    ) {
      isInCurrentAssignment = true;
    }
    return (
      question.isPublished ||
      question.status === 'Deleted' ||
      isInAssignment ||
      isInCurrentAssignment ||
      question.deletedAt ||
      !this.props.editPermission
    );
  };

  setNoBorderRed = () => {
    const copyQuestion = this.getCopyOfObject(this.state.question);
    copyQuestion.redBorderC = false;
    copyQuestion.redBorderD = false;
    this.setState({ question: copyQuestion });
  };

  getAnswers = (question, assignment) => {
    const selectOptions = orderOptions.filter(
      (option) => !this.optionIsSelected(option),
    );
    const isDisabled = this.isQuestionDisabled(
      question,
      assignment,
      this.props.chapter,
    );
    const indexArray = [0, 1, 2];
    if (!Array.isArray(question.answers)) {
      question.answers = JSON.parse(question.answers);
      this.setState({ question });
    }
    const incorrectAnswers = question.answers
      .filter((a) => !a.correct)
      .sort((a, b) => a.id - b.id);
    let correctAnswer = question.answers.filter((a) => a.correct)[0];
    let maxId = 0;
    let aux = 0;
    question.answers.forEach((nAnswer) => {
      if (nAnswer) {
        aux = nAnswer.id;
        if (aux > maxId) {
          maxId = aux;
        }
      }
    });
    let plus = 1;
    if (!correctAnswer) {
      correctAnswer = {
        id: maxId + plus,
        correct: true,
        order: 0,
        text: '',
        wasAIGenerated: false,
        substantialChanges: false,
      };
      plus++;
      this.setState({ question });
      question.answers.push(correctAnswer);
    }
    indexArray.forEach((index) => {
      if (!incorrectAnswers[index]) {
        const answerTemplate = {
          id: maxId + plus,
          correct: false,
          order: 0,
          text: '',
        };
        question.answers.push(answerTemplate);
        incorrectAnswers.push(answerTemplate);
        plus++;
      }
    });
    incorrectAnswers.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
    let answers = [
      <AnswerTextArea
        title="Option A (Correct)"
        onChangeAnswer={(value) => {
          this.onChangeAnswer(value, correctAnswer.id);
        }}
        value={correctAnswer}
        selectOptions={selectOptions}
        onSelectChange={this.onSelectChange}
        redBorder={this.state.question.redBorderA}
        disabled={isDisabled}
        isCorrect
        isFlashCardMode={this.state.isFlashCardMode}
        key="question_A"
        setNoBorderRed={this.setNoBorderRed}
        substantialChangeCheckbox={this.substantialChangeCheckbox}
        originalResponse={question.originalResponse}
        question={question}
      />,
    ];
    if (!this.state.isFlashCardMode) {
      answers = answers.concat([
        <AnswerTextArea
          title="Option B"
          onChangeAnswer={(value) => {
            this.onChangeAnswer(value, incorrectAnswers[0].id);
          }}
          value={incorrectAnswers[0]}
          selectOptions={selectOptions}
          onSelectChange={this.onSelectChange}
          redBorder={this.state.question.redBorderB}
          disabled={isDisabled}
          key="question_B"
          setNoBorderRed={this.setNoBorderRed}
          originalResponse={question.originalResponse}
        />,
        <AnswerTextArea
          title="Option C"
          onChangeAnswer={(value) => {
            this.onChangeAnswer(value, incorrectAnswers[1].id);
          }}
          value={incorrectAnswers[1]}
          selectOptions={selectOptions}
          onSelectChange={this.onSelectChange}
          redBorder={this.state.question.redBorderC}
          disabled={isDisabled}
          key="question_C"
          setNoBorderRed={this.setNoBorderRed}
          originalResponse={question.originalResponse}
        />,
        <AnswerTextArea
          title="Option D"
          onChangeAnswer={(value) => {
            this.onChangeAnswer(value, incorrectAnswers[2].id);
          }}
          name="pageNumber"
          value={incorrectAnswers[2]}
          selectOptions={selectOptions}
          onSelectChange={this.onSelectChange}
          redBorder={this.state.question.redBorderD}
          disabled={isDisabled}
          key="question_D"
          setNoBorderRed={this.setNoBorderRed}
          originalResponse={question.originalResponse}
        />,
      ]);
    }
    return answers;
  };

  getCopyOfObject = (object) => {
    const result = { ...object };
    return result;
  };

  getNonEmptyValue = (text, altText) => {
    if (text && text !== '' && typeof text !== undefined) {
      return text;
    }
    if (altText) {
      return altText;
    }
    return null;
  };

  changeQuestionMode = () => {
    const { question, isFlashCardMode } = this.state;
    const newQuestion = this.getCopyOfObject(question);

    // update the question mode
    newQuestion.flashQuestionNotes = newQuestion.notes;
    newQuestion.multipleOptionMode = isFlashCardMode;
    newQuestion.flashMode = !isFlashCardMode;

    this.setState({ isFlashCardMode: !isFlashCardMode, question: newQuestion });
  };

  showConfirmModal = () => {
    const isOptionDisabled =
      (this.state.question.multipleOptionMode && this.state.isFlashCardMode) ||
      (!this.state.question.multipleOptionMode && !this.state.isFlashCardMode);
    const hasSameQuestionText =
      this.state.question.flashQuestionText === this.state.question.text;

    if (isOptionDisabled && hasSameQuestionText) {
      this.props.openModal({
        modalTitle: 'Please confirm if the question text is correct',
        modalContent: (
          <SimpleConfirmation
            onConfirm={this.onSubmit}
            onCancel={this.props.closeModal}
            text={
              <div>
                The question text for the flash card mode and the multiple
                question mode is the same. Are you sure you want to continue?
              </div>
            }
          />
        ),
      });
    } else {
      this.onSubmit();
    }
  };

  onSubmit = () => {
    const questionToUpdate = { ...this.state.question };
    const correctAnswer = questionToUpdate.answers.filter((a) => a.correct)[0];

    questionToUpdate.answers.forEach((answer) => {
      if (answer.text === ' ') {
        answer.text = '';
      }
    });
    if (questionToUpdate.flashQuestionNotes === null) {
      questionToUpdate.flashQuestionNotes = '';
    } else if (questionToUpdate.notes === null) {
      questionToUpdate.notes = '';
    }
    if (correctAnswer.wasAIGenerated)
      correctAnswer.substantialChanges = this.state.substantialChangesAnswer;

    if (
      this.state.question &&
      this.state.flashMode &&
      !questionToUpdate.flashQuestionText
    ) {
      questionToUpdate.flashQuestionText =
        this.state.question.flashQuestionText;
    }
    questionToUpdate.isCreatingAiQuestion = this.props.isCreatingAiQuestion;
    questionToUpdate.customPromptId = null;
    if (this.state.customPromptSelect && this.state.customPromptSelect.id) {
      questionToUpdate.customPromptId = this.state.customPromptSelect.id;
    } else if (
      this.state.userCustomPrompts &&
      this.props.userCustomPrompts.length
    ) {
      questionToUpdate.customPromptId = this.props.userCustomPrompts[0].id;
    }

    const incorrectAnswers = questionToUpdate.answers.filter((a) => !a.correct);
    let deleting = false;
    if (this.validate(questionToUpdate)) {
      if (correctAnswer && incorrectAnswers) {
        const indexArray = [3, 2, 1, 0];
        indexArray.forEach((i) => {
          if (questionToUpdate.answers[i]) {
            if (questionToUpdate.answers[i].text === '') {
              if (questionToUpdate.answers[i].updatedAt) deleting = true;
              questionToUpdate.answers.splice(i, 1);
            }
          }
        });
        const assignment = this.props.assignment
          ? this.props.assignment.id
          : null;
        if (this.state.isFlashCardMode) {
          indexArray.forEach((i) => {
            if (questionToUpdate.answers[i]) {
              if (questionToUpdate.answers[i].text === '') {
                if (questionToUpdate.answers[i].updatedAt) deleting = true;
                questionToUpdate.answers.splice(i, 1);
              }
            }
          });
        }
        if (!this.props.question) {
          if (!questionToUpdate.softwarePage) {
            delete questionToUpdate.softwarePage;
          }
          if (!questionToUpdate.documentPage) {
            delete questionToUpdate.documentPage;
          }
          questionToUpdate.chapter = this.props.chapter.id;
          questionToUpdate.multipleOptionMode = !this.state.isFlashCardMode;
          questionToUpdate.flashMode = this.state.isFlashCardMode;
          this.props.onCreateQuestion(questionToUpdate, assignment);
          if (this.state.question) {
            if (this.state.question.answers.length > 0) {
              this.state.question.answers.map((a) => {
                a.text = '';
              });
            }
          }
        } else {
          questionToUpdate.substantialChanges =
            this.state.substantialChangesQuestion;
          questionToUpdate.aiquestion = JSON.stringify(
            questionToUpdate.aiquestion,
          );
          this.props.onEditQuestion(questionToUpdate, assignment);
        }
      }
      this.props.closeModal();
      if (deleting && correctAnswer) {
        deleting = false;
        this.refreshForm();
      }

      this.props.closeModal();
    } else {
      this.props.showWarning(
        'Input error!',
        'Please review the errors in the form',
      );
    }
  };

  isValidString = (text) => text && text !== '' && text.trim() !== '';

  validate = (question) => {
    const correctAnswer = question.answers.filter((a) => a.correct)[0];
    const incorrectAnswers = question.answers.filter((a) => !a.correct);
    incorrectAnswers.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
    let incorrectWithText = 0;
    let answerIncorrectWithText;
    incorrectAnswers.forEach((e) => {
      if (e.text !== '' && e.text !== ' ') {
        incorrectWithText++;
        answerIncorrectWithText = e;
      }
    });
    question.redBorderB = false;
    if (this.state.isFlashCardMode) {
      question.redBorderText =
        !this.isValidString(question.flashQuestionText) ||
        this.countCharacters(question.flashQuestionText) > 1000;
      this.setState({ question });
      const hasCorrect = !!(
        correctAnswer && this.isValidString(correctAnswer.text)
      );
      question.redBorderA = !hasCorrect;
      return (
        !question.redBorderFlashQuestionText &&
        !question.redBorderText &&
        !question.redBorderA
      );
    }
    const hasCorrect = !!(
      correctAnswer && this.isValidString(correctAnswer.text)
    );
    question.redBorderA = !hasCorrect;
    const hasOneIncorrect =
      incorrectAnswers && incorrectWithText > 1
        ? true
        : !!(
            answerIncorrectWithText &&
            this.isValidString(answerIncorrectWithText.text)
          );
    question.redBorderB = !hasOneIncorrect;
    question.redBorderText =
      !this.isValidString(question.text) ||
      this.countCharacters(question.text) > 1000;

    this.setState({ question });

    if (question.isCreatingAiQuestion && !question.customPromptId) {
      this.setState({
        errorCustom: true,
      });
      return false;
    }
    this.setState({
      errorCustom: false,
    });

    return (
      !question.redBorderFlashQuestionText &&
      !question.redBorderText &&
      !question.redBorderA &&
      !question.redBorderB /*
        (!question.redBorderB || !question.redBorderC || !question.redBorderD) */
    );
  };

  getOptions = (selectedQuestion, isSubmitQuestion) => {
    let options = [];
    if (this.props.editPermission) {
      options.push({
        name: this.state.isFlashCardMode
          ? 'Convert to Multiple'
          : 'Convert to Flashcard',
        event: this.changeQuestionMode,
      });
      if (!isSubmitQuestion) {
        if (selectedQuestion) {
          if (!selectedQuestion.isPublished) {
            if (this.props.assignment) {
              const assignment = this.getCopyOfObject(this.props.assignment);
              assignment.disabledQuestions = _.pluck(
                assignment.disabledQuestions,
                'id',
              );
              const isDisabled = assignment.disabledQuestions.includes(
                selectedQuestion.id,
              );
              if (isDisabled) {
                assignment.disabledQuestions =
                  assignment.disabledQuestions.filter(
                    (q) => q !== selectedQuestion.id,
                  );
                if (selectedQuestion.sourceQuestion) {
                  assignment.disabledQuestions =
                    assignment.disabledQuestions.filter(
                      (q) => q !== selectedQuestion.sourceQuestion,
                    );
                }
              } else {
                assignment.disabledQuestions.push(selectedQuestion.id);
                if (selectedQuestion.sourceQuestion) {
                  assignment.disabledQuestions.push(
                    selectedQuestion.sourceQuestion,
                  );
                }
              }
              options = options.concat([
                {
                  name: isDisabled ? 'Enable' : 'Disable',
                  event: () =>
                    this.props.onEditAssignment(
                      _.pick(assignment, 'disabledQuestions', 'id', 'status'),
                    ),
                },
              ]);
            }
            if (selectedQuestion.sourceQuestion) {
              options.push({
                name: 'View Live Question',
                event: () =>
                  this.props.loadPublishedQuestion(
                    this.state.question.sourceQuestion,
                  ),
              });
            }
          } else {
            options = [
              {
                name: 'View Editing Question',
                event: () => this.props.loadPublishedQuestion(null),
              },
            ];
          }
        }

        options.push({
          name: 'Check spelling',
          event: () => this.props.checkQuestionSpelling(this.state.question.id),
        });
        options.push({
          name: 'Question history',
          event: () => {
            this.props.getQuestionHistory(this.state.question.id);
          },
        });
        options.push({
          name: 'Delete Question',
          event: () =>
            this.props.deleteQuestion(
              [this.state.question],
              this.props.assignment ? this.props.assignment.id : null,
            ),
        });
      }
    }
    return options;
  };

  substantialChangeCheckbox = (isQuestion) => (
    <Row className={`ImageRow mb-2 ${!isQuestion ? 'pl-3 mt-3' : null}`}>
      <input
        type="checkbox"
        htmlFor="substantialChanges"
        value={
          isQuestion
            ? this.state.substantialChangesQuestion
            : this.state.substantialChangesAnswer
        }
        onChange={(e) => {
          if (isQuestion)
            this.setState({
              substantialChangesQuestion:
                !this.state.substantialChangesQuestion,
            });
          if (!isQuestion) {
            this.setState({
              substantialChangesAnswer: !this.state.substantialChangesAnswer,
            });
          }
        }}
        id={
          isQuestion
            ? 'substantialCheckboxQuestion'
            : 'substantialCheckboxAnswer'
        }
        checked={
          isQuestion
            ? this.state.substantialChangesQuestion
            : this.state.substantialChangesAnswer
        }
      />
      <label
        className="mb-0"
        htmlFor={
          isQuestion
            ? 'substantialCheckboxQuestion'
            : 'substantialCheckboxAnswer'
        }
      >
        {`Substantial Changes Made to AI Generated ${
          isQuestion ? ' Question' : 'Answer'
        }`}
      </label>
    </Row>
  );

  getQuestionTitle = (question) => {
    if (question.sourceQuestion || question.status === 'New') {
      return (
        <span>
          {question.id ? `Question: ${question.id}` : 'Question'}
          <i
            className="fa fa-circle"
            style={{ fontSize: '10px', marginLeft: '5px', color: '#0562ff' }}
          />
        </span>
      );
    }
    return question.id ? `Question: ${question.id}` : 'Question';
  };

  getQuestionHistory = (question) => {
    if (question.createdBy) {
      return `Created by: ${question.createdBy}`;
    }
    if (question.submittedBy) {
      return `Submitted by: ${question.submittedBy}`;
    }
  };

  getCreationQuestionDate = (question) => {
    const date = new Date(Number(question.createdAt));

    const formattedDate = date.toLocaleDateString('en-US', {
      month: 'long',
      day: '2-digit',
      year: 'numeric',
    });
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    });

    const formattedDateTime = question.createdAt
      ? `Created on: ${formattedDate} ${formattedTime}`
      : '';
    return formattedDateTime;
  };

  onSelect = (event) => {
    let selectPrompt;
    const { userCustomPrompts } = this.state;
    const selectedIndex = userCustomPrompts.findIndex(
      (item) => item.prompt.replace('USER_PROMPT ', '') === event,
    );

    userCustomPrompts.forEach((item) => {
      if (item.prompt.replace('USER_PROMPT ', '') === event) {
        selectPrompt = item;
      }
    });

    // Here the selected item is moved to the first position
    if (selectedIndex > 0) {
      const selectedPrompt = userCustomPrompts.splice(selectedIndex, 1);
      userCustomPrompts.unshift(selectedPrompt[0]);
    }
    this.setState({
      customText: event,
      addCustom: !this.state.addCustom,
      customPromptSelect: selectPrompt,
      errorCustom: false,
      userCustomPrompts,
      createCustom: false,
    });
  };

  handleCreateUserCustomPrompt() {
    if (!this.state.customText) {
      this.setState({
        errorCustom: true,
      });
    } else {
      const { userCustomPrompts } = this.state;
      let newArray = [];
      if (userCustomPrompts && userCustomPrompts.length) {
        newArray = [{ prompt: this.state.customText }, ...userCustomPrompts];
      } else {
        newArray.push({ prompt: this.state.customText });
      }
      this.setState({
        userCustomPrompts: newArray,
        customPromptSelect: this.state.customText,
        createCustom: false,
        addCustom: false,
        creatingAiPrompt: true,
      });
    }

    this.props.createUserCustomPrompt(
      this.state.customText,
      this.props.chapter.id,
    );
  }

  handleChangeCustom() {
    if (!this.state.addCustom) {
      this.setState({ customText: '' });
    }
    this.setState({ addCustom: !this.state.addCustom });
  }

  handlePrompt(event) {
    let existPrompt = true;

    if (this.state.userCustomPrompts && this.state.userCustomPrompts.length) {
      this.state.userCustomPrompts.forEach((item) => {
        if (item.prompt === event) {
          existPrompt = false;
        }
      });
    }
    this.setState({ customText: event, createCustom: existPrompt });
  }

  showSuggestionValidation() {
    const question = this.state.question;
    if (!question?.text || !question?.aiSuggestion) {
      return false;
    }
    return true;
  }

  render() {
    const { isFlashCardMode } = this.state;
    let question = this.state.question;
    if (this.props.publishedQuestion.id) {
      question = this.props.publishedQuestion;
      question.isPublished = true;
    }
    const isDisabled = this.isQuestionDisabled(
      question,
      this.props.assignment,
      this.props.chapter,
    );
    const showSoftwarePageButton =
      question.softwarePage && this.props.goToPdfPage;
    const backgroundColor = this.props.isCreatingAiQuestion
      ? '#cbc0f9'
      : '#f0f3f5';
    const isSubmitQuestion = question && question.submit;

    const isLoadingSuggestionInBackground =
      this.state.question?.aiSuggestionStatus === AI_TASK_STATUS.IN_PROGRESS;

    return (
      <Col
        md={9}
        style={{
          background: backgroundColor,
        }}
        className="form-column QuestionDetailsContainer"
      >
        {
          /* (!this.props.question ||
          (question &&
            question.originalResponse &&
            question.originalResponse.adminUser)) && */ <div className="mt-3 ml-3">
            <span>
              {
                /* question 
              question.originalResponse &&
              question.originalResponse.adminUser */
                question.originalResponse?.ai_question
                  ? 'Edit AI question'
                  : 'Create AI question'
              }
            </span>
            <label
              className="switch switch-3d switch-primary ml-2 mt-1"
              style={{ width: 25, height: 15 }}
            >
              <input
                type="checkbox"
                className="switch-input"
                checked={this.props.isCreatingAiQuestion}
                onChange={() => {
                  this.props.setIsCreatingAiQuestion(
                    !this.props.isCreatingAiQuestion,
                  );
                }}
              />
              <span className="switch-label" />
              <span
                className="switch-handle"
                style={{ height: 15, width: 15 }}
              />
            </label>
          </div>
        }
        <Row className="dotsRow">
          <DropDown
            data-test="question-options-dropdown"
            options={this.getOptions(question)}
            shouldCloseAfter
            style={{
              position: 'absolute',
              right: '5px',
              zIndex: '5',
              marginTop: '10px',
            }}
          />
        </Row>
        {/* Modal Question history */}
        {this.props.showModalHistoryQuestion === true && (
          <QuestionHistoryModal
            showModal={this.props.showModalHistoryQuestion}
            questionHistory={this.props.questionHistory}
            setShowModalQuestionHistory={this.props.setShowModalQuestionHistory}
          />
        )}
        {/* Question input section */}
        <QuestionTextArea
          title={this.getQuestionTitle(question)}
          onChangeQuestion={this.onChangeQuestion}
          value={!isFlashCardMode ? question.text : question.flashQuestionText}
          redBorder={question.redBorderText}
          disabled={isDisabled}
          sourceQuestion={question.sourceQuestion}
          loadPublishedQuestion={this.props.loadPublishedQuestion}
          selectedQuestion={question}
          questionHistory={this.getQuestionHistory(question)}
          creationQuestionDate={this.getCreationQuestionDate(question)}
          improveQuestionWithAi={this.props.improveQuestionWithAi}
          onChangeIncludeNotesInSuggestion={
            this.onChangeIncludeNotesInSuggestion
          }
          isSaveQuestion={this.props.isSaveQuestion}
          isCreatingAiQuestion={this.props.isCreatingAiQuestion}
        />
        {question.wasAIGenerated && this.substantialChangeCheckbox(true)}
        {/* No empty question validation */} {/* Modal question history */}
        {question.redBorderText &&
          this.countCharacters(question.text) === 0 && (
            <span className="redTextQuestion">
              You must fill in the question.
            </span>
          )}
        {question.redBorderText &&
          this.countCharacters(question.text) > 1000 && (
            <span className="redTextQuestion">
              Question should not exceed 1000 characters.
            </span>
          )}
        <div />
        {/* Ai suggestion  section */}
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {isLoadingSuggestionInBackground && (
            <>
              <div
                style={{
                  marginLeft: '30px',
                }}
              >
                <Spinner
                  color="rgba(223,36,218,1)"
                  style={{
                    padding: '10px 0 0 0',
                    textAlign: 'unset',
                  }}
                />
              </div>

              <Row className="aiSuggestion">
                <i className="fa fa-lightbulb-o" />
                <span>
                  {`Preparing suggestion for question ${question.id}`}
                </span>
              </Row>
            </>
          )}

          {!isLoadingSuggestionInBackground &&
            this.showSuggestionValidation() && (
              <Row className="aiSuggestion">
                <i className="fa fa-lightbulb-o" />
                <span>{question.aiSuggestion}</span>
              </Row>
            )}
        </div>
        {/* Image upload section */}
        <Row className="ImageRow">
          <span>Question Image:</span>
          <UploadInput
            editPermission={this.props.editPermission}
            imageUrl={question.imageUrl}
            accept="image/x-png,image/gif,image/jpeg,image/jpg"
            chapter={{}}
            deletePdfChapter={() => {
              const question = Object.assign(this.state.question);
              question.imageUrl = null;
              delete question.fileImageUrl;
              this.setState({ question });
            }}
            updateQuestionPdfProcessing={() => {}}
            questionPdfProcessing={false}
            updateChapter={(chapter) => {
              const question = Object.assign(this.state.question);
              question.fileImageUrl = chapter.pdf;
              question.imageUrl = chapter.pdf.name;
              this.setState({ question });
            }}
            className="questionImageUrl"
          />
        </Row>
        {/* Answers section */}
        {this.getAnswers(this.state.question, this.props.assignment)}
        {/* Pages input section */}
        <Row className="QuestionTextAreaContainer">
          <Col md={6}>Further Reading</Col>
          <Col md={6} className="flexEnd">
            Document Page
            <input
              name="documentPage"
              data-test="question-document-page-input"
              value={question.documentPage ? question.documentPage : ''}
              onChange={this.onChangePageNumber}
              disabled={isDisabled}
              className="SmallInput BorderTextArea MarginRight15 form-control"
            />
            {!showSoftwarePageButton ? (
              'Software Page'
            ) : (
              <span
                className="SpanLink"
                onClick={() => {
                  this.props.goToPdfPage(question.softwarePage);
                  this.props.setCurrentPage(question.softwarePage);
                }}
              >
                Software Page
              </span>
            )}
            <input
              name="softwarePage"
              data-test="question-software-page-input"
              value={question.softwarePage ? question.softwarePage : ''}
              onChange={this.onChangePageNumber}
              disabled={isDisabled}
              className="SmallInput BorderTextArea form-control"
              type="number"
            />
          </Col>
        </Row>
        {/* Further reading section */}
        <Col md={12}>
          <WYSIWYG
            onChange={this.onChangeFurtherReading}
            height={350}
            redBorder={this.props.redBorder}
            value={question.notes}
            updateEditorView={question.updateEditorView}
            disabled={isDisabled}
            submit={this.onSubmit}
            showWarning={this.props.showWarning}
          />
          {question.notesError && question.notesError.length > 0 && (
            <span className="MisspelledWords">
              Misspelled words: {question.notesError.join()}
            </span>
          )}
        </Col>
        {this.props.isCreatingAiQuestion && (
          <Col md={12} className="mt-3">
            <span>Custom Prompt</span>
            {this.state.errorCustom && (
              <span style={{ color: 'red' }} className="ml-1">
                required
              </span>
            )}

            <div className="mt-3">
              <div>
                {!this.state.addCustom ? (
                  <select
                    value={this.state.customPrompt}
                    className="form-control mb-2"
                    onChange={(e) => {
                      this.onSelect(e.target.value);
                    }}
                  >
                    {this.state.userCustomPrompts &&
                      this.state.userCustomPrompts.map((p, index) => (
                        <option
                          key={index}
                          value={p.prompt.replace('USER_PROMPT ', '')}
                        >
                          {' '}
                          {p.prompt.replace('USER_PROMPT ', '').length > 70
                            ? `${p.prompt
                                .replace('USER_PROMPT ', '')
                                .substring(0, 70)}...`
                            : p.prompt.replace('USER_PROMPT ', '')}
                        </option>
                      ))}
                  </select>
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <textarea
                      type="text"
                      value={this.state.customText}
                      disabled={false}
                      style={{
                        width: '100%',
                        border: this.state.errorCustom ? '1px solid red' : null,
                      }}
                      onChange={(e) => {
                        this.handlePrompt(e.target.value);
                      }}
                    />
                    {this.state.createCustom && (
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className="check">
                          <i
                            className="fa fa-check fa-lg"
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.handleCreateUserCustomPrompt()}
                          />
                        </div>
                        <div className="cross">
                          <i
                            className="fa fa-close fa-lg"
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              this.setState({
                                addCustom: !this.state.addCustom,
                              })
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div>
                  <Button
                    className="btn btn-info mt-2 marginLeft"
                    onClick={() => this.handleChangeCustom()}
                  >
                    {' '}
                    {this.state.addCustom ? 'Select Custom' : 'Add Custom'}
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        )}
        <div style={{ marginBottom: '250px' }} />
        <Footer backgroundColor={backgroundColor} />
        <NotificationSystem ref="notificationSystem" />
      </Col>
    );
  }
}

QuestionDetails.defaultProps = {
  defaultQuestion: {
    notes: '',
    softwarePage: null,
    documentPage: null,
    text: '',
    updateEditorView: true,
    answers: [
      { correct: true, order: 0, text: '', id: 1 },
      { correct: false, order: 0, text: '', id: 2 },
      { correct: false, order: 0, text: '', id: 3 },
      { correct: false, order: 0, text: '', id: 4 },
    ],
    multipleOptionMode: true,
    flashMode: false,
    defaultQuestion: true,
  },
  defaultFlaschardQuestion: {
    softwarePage: null,
    documentPage: null,
    flashQuestionText: '',
    flashMode: true,
    updateEditorView: true,
    multipleOptionMode: false,
    answers: [
      { correct: true, order: 0, text: '', id: 1 },
      { correct: false, order: 0, text: '', id: 2 },
      { correct: false, order: 0, text: '', id: 3 },
      { correct: false, order: 0, text: '', id: 4 },
    ],
    defaultQuestion: true,
  },
};

export class AnswerTextArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hide: true,
    };
  }

  isOptionDisabled = (value) =>
    !this.props.selectOptions.find((option) => option.value === value);

  render() {
    const value = this.props.value ? this.props.value : {};
    const title = this.props.title ? this.props.title : {};
    const redBorder =
      this.props.redBorder && this.props.redBorder === true
        ? this.props.redBorder
        : false;

    const nonModifiedAnswer = nonModifiedQuestionOrAnswer(
      false,
      this.props.question,
      value,
    );
    return (
      <Row className="QuestionTextAreaContainer">
        <Col md={10}>
          {this.props.title}
          <div
            className="more-options-btn more-options-btn-question"
            onClick={() => this.setState({ hide: !this.state.hide })}
            title="more text"
            data-test="more-text-button"
          >
            {' Ω'}
          </div>
        </Col>

        <Col md={2} className="question-area-select">
          {!this.props.isFlashCardMode && (
            <select
              className="QuestionTextAreaSelect BorderTextArea form-control"
              data-test="question-order-select-input"
              value={value.order}
              onChange={(event) => {
                this.props.onSelectChange(value.id, event);
              }}
              disabled={this.props.disabled}
              tabIndex={-1}
            >
              <option value={0}>Random</option>
              <option value={1} disabled={this.isOptionDisabled(1)}>
                1
              </option>
              <option value={2} disabled={this.isOptionDisabled(2)}>
                2
              </option>
              <option value={3} disabled={this.isOptionDisabled(3)}>
                3
              </option>
              <option value={4} disabled={this.isOptionDisabled(4)}>
                4
              </option>
            </select>
          )}
        </Col>
        <Col md={12} className="width100" onBlur={this.props.setNoBorderRed}>
          <WYSIWYG
            id={this.props.id}
            hide={this.state.hide}
            isQuestion={false}
            isInput
            onChange={this.props.onChangeAnswer}
            height={1}
            redBorder={this.props.redBorder}
            value={value.text ? value.text : ' '}
            disabled={this.props.disabled}
            modalSize
            isAnswer={value.id}
            showWarning={this.props.showWarning}
            nonModifiedQuestionOrAnswer={nonModifiedAnswer}
          />

          {title === 'Option A (Correct)' && redBorder && (
            <span className="redText">
              You must fill in one correct answer.
            </span>
          )}
          {title === 'Option B' && redBorder && (
            <span className="redText">
              You must fill in at least one incorrect answer.
            </span>
          )}
          {title !== 'Option A (Correct)' &&
            title !== 'Option B' &&
            redBorder && (
              <span className="redText">
                You should fill the previous answer
              </span>
            )}
          {value.textError && value.textError.length > 0 && (
            <span className="MisspelledWords">
              Misspelled words: {value.textError.join()}
            </span>
          )}
          {this.props.substantialChangeCheckbox &&
            this.props.value.wasAIGenerated &&
            this.props.substantialChangeCheckbox(false)}
        </Col>
        {this.props.originalResponse &&
          this.props.originalResponse.ai_answer &&
          this.props.isCorrect && (
            <Col md={12}>
              <div
                className="mt-2 titleOriginalResponseContainer"
                onClick={() =>
                  this.setState({
                    viewOriginalAnswer: !this.state.viewOriginalAnswer,
                  })
                }
              >
                <span className="title">Original AI Answer</span>
              </div>
              <div className="originalResponseContainer">
                <span className="text">
                  {this.props.originalResponse.ai_answer}
                </span>
              </div>
            </Col>
          )}
      </Row>
    );
  }
}

export class QuestionTextArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hide: true,
      customPrompt: '',
      textInitial: this.props.value,
      idInitial: this.props.selectedQuestion.id,
    };
  }

  render() {
    const { selectedQuestion } = this.props;
    const value1 = this.props.value === '' ? ' ' : this.props.value;
    const nonModifiedQuestion = nonModifiedQuestionOrAnswer(
      true,
      selectedQuestion,
      null,
    );
    const deletedByAdmin =
      selectedQuestion &&
      selectedQuestion.submit &&
      selectedQuestion.submit.deletedBy === 'ADMIN';

    const suggestionInProgress =
      selectedQuestion?.aiSuggestionStatus === AI_TASK_STATUS.IN_PROGRESS;
    const isButtonDisabled =
      !this.props.selectedQuestion.id ||
      this.state.textInitial !== this.props.selectedQuestion.text ||
      suggestionInProgress;
    if (this.props.selectedQuestion.id) {
      if (this.state.idInitial !== this.props.selectedQuestion.id) {
        this.setState({
          idInitial: this.props.selectedQuestion.id,
          textInitial: this.props.value,
        });
      } else {
        if (
          this.props.isSaveQuestion &&
          this.state.idInitial === this.props.selectedQuestion.id
        )
          this.setState({
            textInitial: this.props.value,
          });
      }
    }
    return (
      <Row className="QuestionTextAreaContainer">
        {selectedQuestion && selectedQuestion.submit && (
          <Col md={3}>
            <div
              style={{
                backgroundColor: !deletedByAdmin
                  ? selectedQuestion.status === 'Submitted'
                    ? 'orange'
                    : '#4dbd74'
                  : 'red',
              }}
              className="statusContainer"
            >
              {!deletedByAdmin ? selectedQuestion.status : 'Deleted by admin'}
            </div>
          </Col>
        )}
        <Col md={selectedQuestion && selectedQuestion.submit ? 9 : 11}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div>{this.props.title}</div>
            <div>{this.props.questionHistory}</div>
            <div>{this.props.creationQuestionDate}</div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <div
                className="more-options-btn more-options-btn-question"
                onClick={() => this.setState({ hide: !this.state.hide })}
                title="more text"
                data-test="more-text-button"
              >
                {' Ω'}
              </div>

              {
                /* In the List and PDF views, only include the "improve question with AI" and 
                "include further reading in ai suggestion" options when editing a question */
                this.props.selectedQuestion.id && (
                  <>
                    <div
                      className={`more-options-btn  ${isButtonDisabled ? 'disabledButton' : 'suggestion-btn'}`}
                      onClick={() => {
                        if (
                          !isButtonDisabled &&
                          this.state.textInitial !== ''
                        ) {
                          this.props.improveQuestionWithAi(
                            this.props.selectedQuestion.id,
                            this.props.selectedQuestion.chapter.id,
                          );
                        }
                      }}
                      title={
                        !isButtonDisabled
                          ? 'Improve question with AI'
                          : suggestionInProgress
                            ? 'Ai suggestion in progress'
                            : 'Unsaved changes'
                      }
                    >
                      <i className="fa fa-lightbulb-o" />
                    </div>
                    <div
                      className="more-options-btn include-further-reading-checkbox"
                      title="Select to include further reading in the context of suggestion"
                      data-test="more-text-button"
                    >
                      <label>
                        <input
                          onClick={() =>
                            this.props.onChangeIncludeNotesInSuggestion(
                              this.props.selectedQuestion,
                              !this.props.selectedQuestion
                                .includeNotesInSuggestion,
                            )
                          }
                          type="checkbox"
                          checked={
                            this.props.selectedQuestion.includeNotesInSuggestion
                          }
                        />
                        Include further reading in ai suggestion
                      </label>
                    </div>
                  </>
                )
              }
            </div>
          </div>
        </Col>
        <Col md={12} className="width100">
          {selectedQuestion &&
          !selectedQuestion.isPublished &&
          selectedQuestion.sourceQuestion ? (
            <div className="centeredTextLink">
              <TextLink
                text="View Live Question"
                onAction={() =>
                  this.props.loadPublishedQuestion(this.props.sourceQuestion)
                }
              />
            </div>
          ) : selectedQuestion &&
            !selectedQuestion.sourceQuestion &&
            selectedQuestion.isPublished ? (
            <div className="centeredTextLink">
              <TextLink
                text="View Editing Question"
                onAction={() => this.props.loadPublishedQuestion(null)}
              />
            </div>
          ) : null}

          <WYSIWYG
            id="question-main"
            hide={this.state.hide}
            isQuestion
            isInput
            onChange={this.props.onChangeQuestion}
            height={1}
            redBorder={this.props.redBorderText}
            value={value1}
            disabled={this.props.isDisabled}
            modalSize
            showWarning={this.props.showWarning}
            nonModifiedQuestionOrAnswer={nonModifiedQuestion}
          />

          {selectedQuestion[`${this.props.name}Error`] &&
            selectedQuestion[`${this.props.name}Error`].length > 0 && (
              <span className="MisspelledWords">
                Misspelled words:{' '}
                {selectedQuestion[`${this.props.name}Error`].join()}
              </span>
            )}
        </Col>
        {((this.props.isCreatingAiQuestion &&
          this.props.selectedQuestion.text.length > 0) ||
          this.props.selectedQuestion.originalResponse?.ai_question) && (
          <Col md={12}>
            <div
              className="mt-2 titleOriginalResponseContainer"
              onClick={() =>
                this.setState({
                  viewOriginalQuestion: !this.state.viewOriginalQuestion,
                })
              }
            >
              <span className="title">Original AI Question</span>
            </div>
            <div className="originalResponseContainer">
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html:
                    this.props.isCreatingAiQuestion &&
                    !this.props.selectedQuestion.originalResponse?.ai_question
                      ? this.props.selectedQuestion.text
                      : this.props.selectedQuestion.originalResponse
                          .ai_question,
                }}
              />
            </div>
          </Col>
        )}
      </Row>
    );
  }
}
