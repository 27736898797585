import './RateQuestionsReport.css';

import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { SimpleInput } from '../../../../commonComponents/Input/Input';
import Spinner from '../../../../commonComponents/Spinner/Spinner';

export default class RateQuestionsReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rates: [],
      rateSearch: '',
    };
  }

  componentDidMount() {
    if (!this.props.rates && !this.ratesIsLoading) {
      this.props.loadRates();
    } else {
      this.setState({
        rates: this.props.rates.slice(1).map((rate) => ({
          id: rate.id,
          name: rate.name,
          description: rate.description,
          shortname: rate.shortname,
          isSelected: false,
        })),
      });
    }
    this.props.getLastRateQuestionsReports();
  }

  areAllRatesSelected = () => {
    let areAllRatesSelected = true;
    let areAllRatesDeselected = true;
    this.state.rates.forEach((currentRate) => {
      if (currentRate.isSelected) {
        areAllRatesDeselected = false;
      } else {
        areAllRatesSelected = false;
      }
    });
    return {
      areAllRatesSelected,
      areAllRatesDeselected,
    };
  };

  onChangeRateSelection = (rateId, newValue) => {
    const checkedRate = this.state.rates.find((r) => r.id === rateId);
    checkedRate.isSelected = newValue;
    this.setState({ rates: this.state.rates });
  };

  changeAllValues = (newValue) => {
    this.setState({
      rates: this.state.rates.map((rate) => {
        rate.isSelected = newValue;
        return rate;
      }),
    });
  };

  renderSelectAllOrNothing = (areAllRatesDeselected) => (
    <Row>
      <Col md={12} className="all-or-none">
        <SimpleInput
          type="checkbox"
          description={areAllRatesDeselected ? 'Select All' : 'Deselect All'}
          value={!areAllRatesDeselected}
          onChange={() => {
            this.changeAllValues(!!areAllRatesDeselected);
          }}
        />
      </Col>
    </Row>
  );

  renderRateAndCheckbox = (rate) => {
    if (rate.id) {
      let textLink = 'no data';
      let report = {};
      if (Array.isArray(this.props.lastRateQuestionsReports)) {
        report = this.props.lastRateQuestionsReports.find(
          (r) => r.rate === rate.id,
        );
        textLink = `${rate.shortname} - ${new Date(report?.updatedAt).toLocaleString()}`;
      }
      const isLoading = report && report.isLoading;

      return (
        <Row key={rate.id}>
          <Col md={12}>
            {isLoading && <Spinner />}
            {!isLoading && report && report.reportLink && (
              <a href={report.reportLink}>
                <span>{textLink}</span>
              </a>
            )}
            <SimpleInput
              description={`(${rate.shortname}) ${rate.name}`}
              type="checkbox"
              value={rate.isSelected}
              onChange={() => {
                this.onChangeRateSelection(rate.id, !rate.isSelected);
              }}
              disabled={isLoading}
            />
          </Col>
        </Row>
      );
    }
    return null;
  };

  generateReportForSelectedRates = () => {
    if (this.state.rates.length > 0) {
      const selectedRatesIds = this.state.rates
        .filter((rate) => rate.isSelected)
        .map((rate) => rate.id);
      this.changeAllValues(false);
      this.props.generateReportForSelectedRates(selectedRatesIds);
    }
  };

  render() {
    const { areAllRatesDeselected } = this.areAllRatesSelected();
    return (
      <Row>
        {this.props.ratesIsLoading ||
        this.props.rateQuestionsReportIsLoading ? (
          <Spinner />
        ) : (
          <div className="d-flex w-100">
            <Col>
              <div
                className={
                  !areAllRatesDeselected
                    ? 'generate-report'
                    : 'generate-report disabled'
                }
                onClick={() => {
                  if (!areAllRatesDeselected) {
                    this.generateReportForSelectedRates(
                      this.props.arrayIdsRateReport,
                    );
                  }
                }}
              >
                Generate Report
              </div>

              <div className="report-title">
                Select the rates you want included in the Rate Questions Report
              </div>
              <span>Search Rate</span>
              <SimpleInput
                value={this.state.rateSearch}
                onChange={(e) =>
                  this.setState({
                    rateSearch: e.target.value,
                  })
                }
              />
              {this.renderSelectAllOrNothing(areAllRatesDeselected)}
              {this.state.rates
                .filter((r) =>
                  this.state.rateSearch !== ''
                    ? `(${r.shortname}) ${r.name}`
                        .toLowerCase()
                        .includes(this.state.rateSearch.toLowerCase())
                    : true,
                )
                .map((rate, index) => this.renderRateAndCheckbox(rate, index))}
            </Col>
          </div>
        )}
      </Row>
    );
  }
}
