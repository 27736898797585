import './QuestionInfiniteList.css';

import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import _ from 'underscore';

import { SimpleInput } from '../../../../commonComponents/Input/Input';
import Spinner from '../../../../commonComponents/Spinner/Spinner';
import {
  allQuestionsPageSize,
  infinitePageSize,
} from '../../../../constants/pagination';
import ConvertHtmlToPlainText from '../../../../utilities/ConvertHtmlToPlainText';
import QuestionBirectionalList from '../QuestionBirectionalList/QuestionBirectionalList';

export default class QuestionInfiniteList extends React.Component {
  constructor(props) {
    super(props);

    this.timeout = null;
    this.state = {
      scrollHeight: 0,
      questionPI: null,
      questionsCheckDeleted: [],
      selectedQuestion: this.props.selectedQuestion,
    };
  }

  componentDidMount() {
    this.props.setSeletedQuestionsForDeleted('', false);

    this.props.amountOfQuestionsInChapter(this.props.chapter.id);
    this.props.onClearQuestions();
    this.props.loadQuestionsOfChapter(
      this.props.chapter.id,
      0,
      this.props.loadAllQuestions ? allQuestionsPageSize : infinitePageSize,
      this.props.assignment ? this.props.assignment.id : null,
      false,
      0,
      this.props.isShowDeletedQuestions,
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedQuestion, questions } = this.props;

    if (this.state.questionPI) {
      const question = questions.find(
        (question) =>
          question.presentationIndex === Number(this.state.questionPI),
      );
      if (question) {
        this.setSelectedQuestion(question);
        this.setState({ questionPI: null });
        return;
      }
    }
    if (this.props.urlQuestion) {
      const question = questions.find(
        (question) => question.id === Number(this.props.urlQuestion),
      );
      if (question) {
        this.setSelectedQuestion(question);
        this.props.setUrlQuestion(null);
        return;
      }
    }

    if (selectedQuestion && selectedQuestion.id) {
      const elem = window.jQuery(`#question-list-id-${selectedQuestion.id}`);
      const selectedQuestionOffset = elem.offset();
      if (
        selectedQuestionOffset &&
        !this.isScrolledIntoView(selectedQuestion.id)
      ) {
        const element = selectedQuestionOffset.top;
        window
          .jQuery('.questionListContainer')
          .scrollTop(this.state.scrollHeight || element);
      }
    }
    if (this.props.questionsForDeleted !== prevProps.questionsForDeleted) {
      this.setState({
        questionsCheckDeleted: this.props.questionsForDeleted,
      });
    }
    if (
      this.props.isShowDeletedQuestions !== prevProps.isShowDeletedQuestions
    ) {
      this.props.setSeletedQuestionsForDeleted('', false);
    }
  }

  componentWillUnmount() {
    this.props.setSelectedQuestion(null);
  }

  componentWillReceiveProps({ questions, selectedQuestion }) {
    if (
      questions &&
      this.props.questions.length > 0 &&
      questions.length === 0
    ) {
      this.props.setSelectedQuestion(null);
    }
  }

  isScrolledIntoView = (id) => {
    const el = document.getElementById(`question-list-id-${id}`);
    if (!el) {
      return false;
    }
    const rect = el.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom - 20;

    // Only completely visible elements return true:
    const isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
    // Partially visible elements return true:
    // isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
  };

  getNonEmptyValue = (text, altText) => {
    if (text && text !== '') {
      return text;
    }
    return altText;
  };

  handleCheckboxDeletedQuestion(question, checkQuestion) {
    let listQuestions = [...this.state.questionsCheckDeleted];
    if (!checkQuestion) {
      listQuestions.push(question);
    } else {
      listQuestions = listQuestions.filter((item) => item.id !== question.id);
    }

    this.setState({ questionsCheckDeleted: listQuestions });
    this.props.setSeletedQuestionsForDeleted(question, checkQuestion);
  }

  renderQuestions = (
    questions,
    selectedQuestion,
    onQuestionClick,
    isSmallList,
    isWaiting,
  ) => {
    const { questionsCheckDeleted } = this.state;
    return questions.map((question, index) => {
      const checkQuestion = questionsCheckDeleted.includes(question);
      let className =
        selectedQuestion && selectedQuestion.id === question.id
          ? 'clickable activeQuestion'
          : 'clickable';
      className =
        question.status === 'Deleted' ? `${className} LineThrough` : className;
      return (
        <div
          style={{ display: 'flex', flexDirection: 'row' }}
          className={`${className} questionList`}
          key={index}
          onClick={() => {
            this.setState({
              scrollHeight: window.jQuery('.questionListContainer').scrollTop(),
            });

            if (!this.props.isWaiting) {
              onQuestionClick(question);
            }
          }}
        >
          <div>
            <p
              id={`question-list-id-${question.id}`}
              key={`question-list-id-${question.id}`}
              className={isSmallList ? 'ml-1' : null}
            >
              <b>{`${question.presentationIndex} `}</b>
              {!isSmallList &&
                ConvertHtmlToPlainText(
                  question.multipleOptionMode
                    ? this.getNonEmptyValue(
                        question.text,
                        question.flashQuestionText,
                      )
                    : this.getNonEmptyValue(
                        question.flashQuestionText,
                        question.text,
                      ),
                )}
            </p>
          </div>
          <div>
            <SimpleInput
              type="checkbox"
              notMb
              onChange={(e) => {
                this.handleCheckboxDeletedQuestion(question, checkQuestion);
              }}
              value={checkQuestion}
              disabled={isWaiting}
            />
          </div>
        </div>
      );
    });
  };

  areThereMoreQuestions = (pagination) =>
    pagination ? pagination.total > pagination.rows * pagination.page : false;

  getSmallList = (
    questions,
    selectedQuestion,
    pagination,
    setSelectedQuestion,
    isWaiting,
  ) => (
    <div className="ListContainerSmall">
      <div className="InfiniteScrollContainer">
        <div className="questionsTotal">
          <i
            onClick={() => setSelectedQuestion(null)}
            className="fa fa-plus fa-lg"
          />
        </div>
        {questions && questions.length > 0 && (
          <div className="questionListContainerSmall">
            <QuestionBirectionalList>
              {this.renderQuestions(
                questions,
                selectedQuestion,
                setSelectedQuestion,
                true,
                isWaiting,
              )}
            </QuestionBirectionalList>
          </div>
        )}
      </div>
    </div>
  );

  loadFunc = (
    notClearOtherQuestions = true,
    paginationPage,
    ignoreMoreQuestions = false,
  ) => {
    const { assignment, chapter, pagination, isLoadingQuestions } = this.props;
    if (!isLoadingQuestions) {
      this.props.loadQuestionsOfChapter(
        chapter.id,
        paginationPage || pagination.page + 1,
        infinitePageSize,
        assignment ? assignment.id : null,
        notClearOtherQuestions,
      );
    }
  };

  setSelectedQuestion = (question) => {
    this.props.setSelectedQuestion(question);
    const scrollTo = document.getElementById(`question-list-id-${question.id}`);
    if (scrollTo) {
      scrollTo.scrollIntoView();
    }
  };

  setSearch = (event) => {
    const searchId = event.target.value;
    this.debounce(() => this.onSearchChange(searchId), 500);
  };

  debounce = (func, wait = 500, immediate = false, ...theArgs) => {
    const context = this;
    const args = theArgs;
    const later = () => {
      this.timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !this.timeout;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };

  onSearchChange = (value) => {
    let question;
    if (!value || value === '') return;
    if (value.length <= 3) {
      question = this.props.questions.find(
        (question) => question.presentationIndex === Number(value),
      );
      if (!question) {
        this.props.onClearQuestions();
        const page = Math.ceil(value / infinitePageSize);
        this.loadFunc(false, page);
        this.setState({ questionPI: value });
      }
    }
    if (value.length >= 4) {
      question = this.props.questions.find(
        (question) => question.id === Number(value),
      );
    }
    if (question) {
      this.setSelectedQuestion(question);
    }
  };

  getRegularList = (
    questions,
    selectedQuestion,
    pagination,
    setSelectedQuestion,
    isWaiting,
  ) => (
    <Col className="ListContainer" md={3}>
      <div className="InfiniteScrollContainer">
        <div className="questionsTotal">
          <b>{`${pagination.total} Questions`}</b>
          <i
            onClick={() => setSelectedQuestion(null)}
            className="fa fa-plus fa-lg floatRight"
            data-test="add-question-button"
          />
          <span className="goToLabel">
            <input
              name="search"
              type="number"
              value={this.state.search}
              onChange={this.setSearch}
              className="SmallInput MarginRight15 form-control"
            />
          </span>
        </div>
        {questions && questions.length > 0 && (
          <div className="questionListContainer" data-test="question-list">
            <QuestionBirectionalList>
              {this.renderQuestions(
                questions,
                selectedQuestion,
                setSelectedQuestion,
                false,
                isWaiting,
              )}
            </QuestionBirectionalList>
          </div>
        )}
        {questions.length === 0 && isWaiting && (
          <div data-test="question-list-loader">
            <Spinner />{' '}
          </div>
        )}
      </div>
    </Col>
  );

  render() {
    const {
      questions,
      selectedQuestion,
      setSelectedQuestion,
      pagination,
      isSmallList,
      isLoadingQuestions,
    } = this.props;
    return isSmallList
      ? this.getSmallList(
          questions,
          selectedQuestion,
          pagination,
          setSelectedQuestion,
          isLoadingQuestions,
        )
      : this.getRegularList(
          questions,
          selectedQuestion,
          pagination,
          setSelectedQuestion,
          isLoadingQuestions,
        );
  }
}

export class QuestionLine extends React.Component {
  render() {
    const { selected, onClick, name } = this.props;
    return (
      <div>
        <a
          className={selected ? 'question-line active' : 'question-line'}
          onClick={onClick}
        >
          {name}
        </a>
      </div>
    );
  }
}
