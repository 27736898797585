import './UsageStatisticsReport.css';

import { useEffect } from 'react';

import { HistoricalReportTable } from './HistoricalReportTable';
import { NewUsageStatisticsReport } from './NewUsageStatisticsReport';

function UsageStatisticsReport({
  // functions
  generateUsageReport,
  getUsageReports,
  // state
  usageStatisticsReports,
  usageStatisticsReportsIsLoading,
  usageStatisticsReportIsLoading,
}) {
  useEffect(() => {
    getUsageReports();
  }, []);

  return (
    <>
      <div className="usage-statistics-report">
        <hr />
        <div>
          <h2>Usage Statistics Report</h2>
          <NewUsageStatisticsReport
            generateUsageReport={generateUsageReport}
            usageStatisticsReportIsLoading={usageStatisticsReportIsLoading}
          />
        </div>

        <div>
          <h4 style={{ marginTop: '50px' }}>Usage Report History</h4>
          <HistoricalReportTable
            reports={usageStatisticsReports}
            usageStatisticsReportsIsLoading={usageStatisticsReportsIsLoading}
            usageStatisticsReportIsLoading={usageStatisticsReportIsLoading}
          />
        </div>
      </div>
    </>
  );
}

export default UsageStatisticsReport;
