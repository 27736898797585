import './Full.css';

import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import {
  resetPasswordUserActionSagas,
  updateAdminSaga,
} from '../../components/Admins/actionsSagas';
import Aside from '../../components/Aside';
import { getIsQuestionOverview } from '../../components/Assignments/selectors';
import Header from '../../components/Header';
import PasswordAlertModal from '../../components/Login/components/PasswordAlertModal';
import {
  getGeneralRolesPermissions,
  getRolesPermissions,
  getUser,
} from '../../components/Login/selectors';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
// import EventListener from 'react-event-listener';
import { setQuery, setSearchFromHeader } from '../../components/Search/actions';
import Sidebar from '../../components/Sidebar/components/SidebarContainer';
import { environmentName } from '../../constants/endpoints';
import * as HeaderOptions from '../../HeaderOptions';
import addAbilityToOpenModal from '../../utilities/modalHOC';
import { connectSocket } from '../../utilities/socketService';
import * as userToken from '../../utilities/userToken';

class Full extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sendActivity: true,
      showAlertPassword: false,
      isPasswordExpired: false,
    };
  }

  componentDidMount() {
    // Connect socket for background requests
    connectSocket();
    if (
      this.props.loggedUser?.validPasswordCriteria === false ||
      this.props.loggedUser?.isPasswordExpired === true
    ) {
      // delete token cookie for redirections to login
      // document.cookie = 'token=; Max-Age=-99999999;';
      userToken.removeUserToken();
    }
    if (this.props.loggedUser?.isLoginAsAdmin === true) {
      this.setState({
        showAlertPassword: false,
      });
    } else if (this.props.loggedUser?.validPasswordCriteria === false) {
      this.setState({
        showAlertPassword: true,
        isPasswordExpired: false,
      });
    } else if (this.props.loggedUser?.isPasswordExpired === true) {
      this.setState({
        showAlertPassword: true,
        isPasswordExpired: true,
      });
    } else {
      // if it's undefined, NOT showing.
      this.setState({
        showAlertPassword: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.loggedUser && this.props.loggedUser.id) {
      Sentry.setUser({
        email: this.props.loggedUser.email,
        id: this.props.loggedUser.id,
      });
    }

    if (
      this.props.loggedUser?.validPasswordCriteria !==
        prevProps.loggedUser?.validPasswordCriteria ||
      this.props.loggedUser?.isPasswordExpired !==
        prevProps.loggedUser?.isPasswordExpired
    ) {
      if (this.props.loggedUser?.isLoginAsAdmin === true) {
        this.setState({
          showAlertPassword: false,
        });
      } else if (this.props.loggedUser?.validPasswordCriteria === false) {
        this.setState({
          showAlertPassword: true,
          isPasswordExpired: false,
        });
      } else if (this.props.loggedUser?.isPasswordExpired === true) {
        this.setState({
          showAlertPassword: true,
          isPasswordExpired: true,
        });
      } else {
        // if it's undefined, NOT showing.
        this.setState({
          showAlertPassword: false,
        });
      }
    }
  }

  static getHeaderConfigFromRoutes(
    routes,
    generalRolesPermissions,
    rolesPermissions,
    user,
  ) {
    const routeWithHeader = routes.reverse().find((route) => route.header);
    const headerOptions = routeWithHeader ? routeWithHeader.header : [];
    if (!user.isSuperAdmin) {
      let filteredHeaderOptions = [];
      headerOptions.map((option) => {
        const auxOption = generalRolesPermissions.find(
          (permission) =>
            (permission !== 'Wheelhouse.Cycles' &&
              permission.split('.').length > 0 &&
              (permission.split('.')[1] === option.name ||
                permission.split('.')[0] === option.name)) ||
            (option.name === 'Books' && permission === 'Books.Books') ||
            (option.name === 'Bibliographies' &&
              permission === 'Bibliography') ||
            (option.name === 'Quals' && permission === 'Quals'),
        );
        if (
          option.name === 'Bibliographies' &&
          rolesPermissions.filter((role) =>
            role.actions.find((action) => action.endsWith('.Bibliography')),
          ).length > 0
        ) {
          filteredHeaderOptions.push(option);
        }
        if (auxOption) {
          filteredHeaderOptions.push(option);
        }
        /* SUBTOPICS PERMISSION */
        if (
          option.name === 'Subtopics' &&
          rolesPermissions.filter((role) =>
            role.actions.find((action) => action.endsWith('Subtopics')),
          ).length > 0
        ) {
          filteredHeaderOptions.push(option);
        }
        /* BOOKS PERMISSION */
        if (
          option.name === 'Books' &&
          rolesPermissions.filter((role) =>
            role.actions.find(
              (action) =>
                action.endsWith('References') || action.startsWith('Books'),
            ),
          ).length > 0
        ) {
          filteredHeaderOptions.push(option);
        }
        /* AI CHAT */
        if (
          option.name === 'AiChat' &&
          user &&
          user.roles &&
          user.roles.find((role) => role.name === 'AI Chat User')
        ) {
          filteredHeaderOptions.push(option);
        }

        /* RATING EXAMS PERMISSION */
        if (
          option.name === 'Rating exams' &&
          rolesPermissions.filter((role) =>
            role.actions.find((action) => action.startsWith('RatingExams')),
          ).length > 0
        ) {
          filteredHeaderOptions.push(option);
        }
        /* QUALS EXAMS PERMISSION */
        if (
          option.name === 'Quals' &&
          rolesPermissions.filter((role) =>
            role.actions.find((action) => action.endsWith('Quals')),
          ).length > 0
        ) {
          filteredHeaderOptions.push(option);
        }
        /* PRACTICE EXAMS PERMISSION */
        if (
          option.name === 'Practice Exams' &&
          generalRolesPermissions.filter(
            (permission) => permission === 'PracticeExams',
          ).length > 0
        ) {
          filteredHeaderOptions.push(option);
        }
        /* REFERRAL PROGRAM PERMISSION */
        if (
          option.name === 'Referral Program' &&
          generalRolesPermissions.filter(
            (permission) => permission === 'PracticeExams',
          ).length > 0
        ) {
          filteredHeaderOptions.push(option);
        }
        /* SEARCH PERMISSIONS */
        if (option.name === 'Search') {
          filteredHeaderOptions.push(option);
        }
        /* ASSIGNMENT PERMISSION */
        if (
          (option.name === 'Assignments' || option.name === 'Wheelhouse') &&
          rolesPermissions.find((permission) =>
            permission.actions.find((action) =>
              action.startsWith('Assignment'),
            ),
          )
        ) {
          filteredHeaderOptions.push(option);
        }
        /* Settings EXAMS PERMISSION */
        if (
          option.name === 'Settings' &&
          generalRolesPermissions.filter((permission) =>
            permission.startsWith('Settings'),
          ).length
        ) {
          option.route = Full.getFirstAvailableSetting(generalRolesPermissions);
          filteredHeaderOptions.push(option);
        }
        /* ACCOUNTS PERMISSION */
        const studentPerm = generalRolesPermissions.find((permission) =>
          permission.startsWith('Students'),
        );
        const adminPerm = generalRolesPermissions.find((permission) =>
          permission.startsWith('Admins'),
        );
        const teamPerm = rolesPermissions.filter((role) =>
          role.actions.find((action) => action.startsWith('Teams')),
        ).length;
        if (
          option.name === 'Accounts' &&
          (studentPerm || adminPerm || teamPerm)
        ) {
          option.route = teamPerm
            ? '/home/accounts/teams'
            : (option.route = studentPerm
                ? '/home/accounts/students'
                : '/home/accounts/admins');
          filteredHeaderOptions.push(option);
        }

        if (
          user &&
          user.role &&
          user.roles.find((role) => role.name === option.requiredRole)
        ) {
          filteredHeaderOptions.push(option);
        }
        return option;
      });
      filteredHeaderOptions = _.uniq(filteredHeaderOptions, 'name');
      return filteredHeaderOptions;
    }
    return headerOptions;
  }

  static getSecondHeaderConfigFromHeaderOptions(headerOptions, router) {
    return (
      headerOptions &&
      headerOptions
        .reverse()
        .find((option) => option.route && router.isActive(option.route))
    );
  }

  static getFirstAvailableSetting(generalRolesPermissions) {
    const permissions = generalRolesPermissions.filter((r) =>
      r.includes('Settings'),
    )[0];
    if (permissions.includes('Permissions')) {
      return '/home/settings/permissions';
    }
    if (permissions.includes('Keys')) {
      return '/home/settings/keys';
    }
    if (permissions.includes('Permissions')) {
      return '/home/settings/permissions';
    }
    if (permissions.includes('StudyProgram')) {
      return '/home/settings/rates';
    }
    if (permissions.includes('TWSP')) {
      return '/home/settings/10week';
    }
    if (permissions.includes('Coupons')) {
      return '/home/settings/coupons';
    }
    if (permissions.includes('newCoupons')) {
      return '/home/settings/newCoupons';
    }
    return '/home/settings/keys';
  }

  getSecondHeaderOptions(generalRolesPermissions, rolesPermissions, user) {
    if (window.location.pathname.startsWith('/home/accounts')) {
      if (user.isSuperAdmin) return HeaderOptions.accounts;

      return HeaderOptions.accounts
        .map((tab) => {
          switch (tab.name) {
            case 'Students':
              return generalRolesPermissions.find((r) => r.includes('Students'))
                ? tab
                : null;
            case 'Admins':
              return generalRolesPermissions.find((r) => r.includes('Admins'))
                ? tab
                : null;
            case 'Roles':
              return generalRolesPermissions.find((r) => r.includes('Roles'))
                ? tab
                : null;
            case 'Teams':
              return generalRolesPermissions.find((r) => r.includes('Teams')) ||
                rolesPermissions.filter((role) =>
                  role.actions.find((action) => action.startsWith('Teams')),
                ).length
                ? tab
                : null;
            default:
              return null;
          }
        })
        .filter((tab) => tab);
      /*
        return HeaderOptions.accounts.filter(tab =>
          generalRolesPermissions.find(permission =>
            permission.startsWith(tab.name + '.')
          )
        ); */
    }
    if (window.location.pathname.startsWith('/home/settings')) {
      if (user.isSuperAdmin) return HeaderOptions.settings;
      return HeaderOptions.settings
        .map((setting) => {
          switch (setting.name) {
            case 'Keys':
              return generalRolesPermissions.find((r) => r.includes('Keys'))
                ? setting
                : null;
            case 'Permissions':
              return generalRolesPermissions.find((r) =>
                r.includes('Permissions'),
              )
                ? setting
                : null;
            case 'Rates & Quals':
              return generalRolesPermissions.find((r) =>
                r.includes('StudyProgram'),
              )
                ? setting
                : null;
            case 'Coupons':
              return generalRolesPermissions.find((r) => r.includes('Coupons'))
                ? setting
                : null;
            case '10 Week':
              return generalRolesPermissions.find((r) => r.includes('TWSP'))
                ? setting
                : null;
            case 'New Coupons':
              return generalRolesPermissions.find((r) =>
                r.includes('newCoupons'),
              )
                ? setting
                : null;
            default:
              return null;
          }
        })
        .filter((tab) => tab);
    }
    if (window.location.pathname.startsWith('/home/questions')) {
      return [];
    }
    if (window.location.pathname.startsWith('/home/rates')) {
      if (user.isSuperAdmin) return HeaderOptions.ratingExamsSubHeaderOptions;

      return HeaderOptions.ratingExamsSubHeaderOptions
        .map((tab) => {
          if (
            rolesPermissions.filter((role) =>
              role.actions.find((permission) =>
                permission.endsWith(`.${tab.name}`),
              ),
            ).length > 0
          )
            return tab;
          return null;
        })
        .filter((tab) => tab);
    }
    if (window.location.pathname.startsWith('/home/wheelhouse')) {
      if (user.isSuperAdmin) return HeaderOptions.wheelhouseSubHeaderOptions;

      const hasAssignmentPagePersmission = generalRolesPermissions.filter(
        (permission) => permission === 'Wheelhouse.Assignments',
      ).length;
      return HeaderOptions.wheelhouseSubHeaderOptions
        .map((tab) => {
          if (
            (tab.name === 'My Assignments' &&
              (hasAssignmentPagePersmission ||
                rolesPermissions.find((permission) =>
                  permission.actions.find((action) =>
                    action.startsWith('Assignment'),
                  ),
                ))) ||
            (tab.name === 'Writers' &&
              (hasAssignmentPagePersmission ||
                rolesPermissions.find((permission) =>
                  permission.actions.find((action) =>
                    action.startsWith('Assignment'),
                  ),
                ))) ||
            (tab.name === 'Assignments' &&
              (hasAssignmentPagePersmission ||
                rolesPermissions.find((permission) =>
                  permission.actions.find((action) =>
                    action.endsWith('ViewAll'),
                  ),
                ))) ||
            (tab.name === 'Reports' &&
              generalRolesPermissions.find((permission) =>
                permission.endsWith(tab.name),
              )) ||
            (tab.name === 'Writers' &&
              generalRolesPermissions.find((permission) =>
                permission.endsWith('Reports'),
              ))
          )
            return tab;
          return null;
        })
        .filter((tab) => tab);
    }
  }

  showHeader(questionOverview) {
    return (
      !window.location.pathname.startsWith('/home/questions/') &&
      !questionOverview
    );
  }

  render() {
    const {
      routes,
      generalRolesPermissions,
      rolesPermissions,
      loggedUser,
      location,
      questionOverview,
    } = this.props;
    const headerOptions = Full.getHeaderConfigFromRoutes(
      routes,
      generalRolesPermissions,
      rolesPermissions,
      loggedUser,
    );
    const secondHeaderOptions = this.getSecondHeaderOptions(
      generalRolesPermissions,
      rolesPermissions,
      loggedUser,
    );
    return (
      <>
        {this.state.showAlertPassword ? (
          <PasswordAlertModal
            show={this.state.showAlertPassword}
            user={this.props.loggedUser}
            updateAdmin={this.props.updateAdmin}
            passwordExpired={this.state.isPasswordExpired}
          />
        ) : null}

        <div
          className={
            window.location.pathname.startsWith('/home/questions/') ||
            questionOverview
              ? 'app noMarginTopContent'
              : 'app'
          }
        >
          {/* this.state.sendActivity === true && (
          <EventListener
            target={document}
            onMouseMoveCapture={this.handleMouseMove}
          />
        ) */}
          {environmentName && environmentName != 'false' ? (
            <div
              className={
                environmentName === 'Development environment'
                  ? 'devBar'
                  : 'stagingBar'
              }
            >
              {environmentName}
            </div>
          ) : null}
          {this.showHeader(questionOverview) && (
            <Header {...this.props} headerOptions={headerOptions} />
          )}
          {this.showHeader(questionOverview) && secondHeaderOptions && (
            <Header
              {...this.props}
              headerOptions={secondHeaderOptions}
              secondHeader
            />
          )}
          <div className={secondHeaderOptions ? 'app-body' : 'noSecondHeader'}>
            {false && <Sidebar {...this.props} />}
            <main
              className="main"
              style={secondHeaderOptions ? {} : { height: '100%' }}
            >
              <div className="container-fluid">{this.props.children}</div>
            </main>
            <Aside />
          </div>
          <ModalWindow
            showModal={this.props.modalVisibility}
            closeModalFunction={this.props.toggleModalVisibility}
            modalContent={this.props.modalContent}
            modalTitle={this.props.modalTitle}
            modalClassName={this.props.modalClassName}
            user={this.props.user}
            {...this.props}
          />
        </div>
      </>
    );
  }
}

Full.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setQuery: (query) => {
    dispatch(setQuery(query));
  },
  setSearchFromHeader: (value) => {
    dispatch(setSearchFromHeader(value));
  },
  resetPassword: (id, passwordFields) => {
    dispatch(resetPasswordUserActionSagas(id, passwordFields));
  },
  updateAdmin: (admin) => {
    dispatch(updateAdminSaga(admin));
  },
});

const mapStateToProps = (state, ownProps) => ({
  generalRolesPermissions: getGeneralRolesPermissions(state),
  rolesPermissions: getRolesPermissions(state),
  loggedUser: getUser(state),
  questionOverview: getIsQuestionOverview(state),
});

export default addAbilityToOpenModal(
  connect(mapStateToProps, mapDispatchToProps)(Full),
);
