import './Reports.css';

import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { CSVLink } from 'react-csv';

import Spinner from '../../../commonComponents/Spinner/Spinner';
import AllCSVReport from './AllCSVReport/AllCSVReport';
import FavoriteQuestionsReport from './FavoriteQuestionsReport/FavoriteQuestionsReport';
import ActiveSubscriptionsContainer from './GetActiveSubscriptions/getActiveSubscriptionsContainer';
import HistoricalUsersReport from './HistoricalUsersReport/HistoricalUsersReportContainer';
import IntercomActiveUsersReportContainer from './intercomActiveUsersReport/IntercomActiveUsersReportContainer';
import MasterUserReportContainer from './masterUserReport/MasterUserReportContainer';
import NumberOfQuestionsEnabledPerRatingExamContainer from './numberOfQuestionsEnabledPerRatingExam/NumberOfQuestionsEnabledPerRatingExamContainer';
import ProfileSheetDataReport from './ProfileSheetDataReport/profileSheetDataReport';
import RateQuestionsReport from './RateQuestionsReport/RateQuestionsReport';
import TopicsReportContainer from './topicsReport/TopicsReportContainer';
import UsageStatisticsReport from './UsageStatisticsReport/UsageStatisticsReport';
import UsersReport from './UsersReport/UsersReport';
import UserSubmittedQuestionsReport from './UserSubmittedQuestionsReport/UserSubmittedQuestionsReport';

const REPORTS = [
  {
    id: 0,
    name: 'Questions with manual order',
    url: 'loadManualOrderChapterQuestionsReport',
    filename: 'Manual-Order-Report',
  },
  {
    id: 1,
    name: 'Questions with TRUE or FALSE answer',
    url: 'loadBooleanAnswerQuestionsReport',
    filename: 'Boolean-Answers-Report',
  },
  {
    id: 2,
    name: '# of questions enabled per rating exam',
    url: 'loadNumberOfQuestionPerRatingExamReport',
    filename: 'Question-Number-Rating-Exam-Report',
  },
  {
    id: 3,
    name: 'All CSV Bibliography Data',
    url: 'allCSVBibliographyData',
    filename: 'Bibliography-Data-Report',
  },
  // @deprecated Not used by the client
  /* {
    id: 4,
    name: 'Users',
    url: 'users',
    filename: 'Users-Report',
  },
  {
    id: 5,
    name: 'Historical Users Report',
    url: 'historical-users',
    filename: 'Historical-Users-Report',
  }, */
  {
    id: 6,
    name: 'Active Subscriptions',
    url: 'active-subscriptions',
    filename: 'Active-Subscriptions-Report',
  },
  {
    id: 7,
    name: 'Completion Status Report',
    url: 'getCompletionStatus',
    filename: 'Completion-Status-Report',
  },
  {
    id: 8,
    name: 'Master User Report',
    url: 'master-user-report',
    filename: 'Master-User-Report',
  },
  {
    id: 9,
    name: 'Profile Sheet Data',
    url: 'profile-sheet-data',
    filename: 'Profile-Sheet-Data',
  },
  {
    id: 10,
    name: 'Pub Hunt Selections',
    url: 'pubHuntSelectionsReport',
    filename: 'pub-hunt-selections',
  },
  {
    id: 11,
    name: 'Favorite Questions Report',
    url: 'favoriteQuestionsReport',
    filename: 'Favorite-Questions-Report',
  },
  {
    id: 12,
    name: 'Admin User List',
    url: 'userListReport',
    filename: 'user-list-report',
  },
  {
    id: 13,
    name: 'Coupons report',
    url: 'couponsReport',
    filename: 'coupons-report',
  },
  {
    id: 14,
    name: 'User Submitted Questions Report',
    url: 'userSubmittedQuestionsReport',
    filename: 'user-submitted-questions-report',
  },
  {
    id: 15,
    name: 'Intercom Active Users Report',
    url: 'intercomActiveUsersReport',
    filename: 'intercom-active-users-report',
  },
  {
    id: 16,
    name: 'Topics',
    url: 'topics',
    filename: 'topics',
  },
  {
    id: 17,
    name: 'Rate Questions Report',
    url: 'rateQuestionsReport',
    filename: 'Rate-Questions-Report',
  },
  {
    id: 18,
    name: 'Usage Statistics Report',
    url: 'usageStatisticsReport',
    filename: 'Usage-Statistics-Report',
  },
];

export default class Reports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedReport: REPORTS[0],
      filename: 'report.csv',
    };
  }

  componentDidMount() {
    if (this.props.rates.length === 0) {
      this.props.loadRates();
    }

    this.props.loadCycles();
  }

  componentDidUpdate() {
    if (this.props.report) {
      document.getElementById('report-download').click();
      this.props.onClearReport();
      this.setState({ filename: 'report.csv' });
    }
  }

  handleDownloadReport = () => {
    this.props.downloadReport(this.state.selectedReport.url);
    const name = REPORTS[this.state.selectedReport.id].filename;
    this.setState({
      filename: `${name}-${moment().format('MMM')}${moment().format(
        'DD',
      )}-${moment().format('YYYY')}.csv`,
    });
  };

  renderReportComponent = () => {
    const { selectedReport } = this.state;
    switch (selectedReport.id) {
      case 2:
        return <NumberOfQuestionsEnabledPerRatingExamContainer />;
      case 3:
        return (
          <AllCSVReport
            rates={this.props.rates}
            ratesIsLoading={this.props.ratesIsLoading}
            loadRates={this.props.loadRates}
            generateReportForSelectedRates={
              this.props.getBibliographyReportForGivenRates
            }
            bibliographyReport={this.props.bibliographyReport}
            bibliographyReportIsLoading={this.props.bibliographyReportIsLoading}
            bibliographyReportError={this.props.bibliographyReportError}
          />
        );
      // @deprecated Not used by the client
      /* case 4:
        return (
          <UsersReport
            graph={this.props.graph}
            setGraph={this.props.setGraph}
            rates={this.props.rates}
            isLoading={this.props.usersReportIsLoading}
            getUsersCount={this.props.getUsersCount}
            usersCount={this.props.usersCount}
          />
        );
      case 5:
        return <HistoricalUsersReport />; */
      case 6:
        return <ActiveSubscriptionsContainer />;
      case 8:
        return <MasterUserReportContainer />;
      case 9:
        return (
          <ProfileSheetDataReport
            cycles={this.props.cycles}
            loadCycles={this.props.loadCycles}
            profileSheetReport={this.props.getProfileSheetReport}
            profileSheetCsv={this.props.profileSheetCsv}
            profileSheetIsLoading={this.props.profileSheetIsLoading}
          />
        );
      case 11:
        return (
          <FavoriteQuestionsReport
            rates={this.props.rates}
            ratesIsLoading={this.props.ratesIsLoading}
            loadRates={this.props.loadRates}
            generateFavoriteQuestionsReport={
              this.props.generateFavoriteQuestionsReport
            }
            getLastFavoriteQuestionsReports={
              this.props.getLastFavoriteQuestionsReports
            }
            lastFavoriteQuestionsReports={
              this.props.lastFavoriteQuestionsReports
            }
            loadingFavoriteQuestionsReport={
              this.props.loadingFavoriteQuestionsReport
            }
          />
        );
      case 14:
        return (
          <UserSubmittedQuestionsReport
            rates={this.props.rates}
            isLoadingRates={this.props.ratesIsLoading}
            loadRates={this.props.loadRates}
            getUserSubmittedQuestionsReportForGivenRates={
              this.props.getUserSubmittedQuestionsReportForGivenRates
            }
            userSubmittedQuestionsReport={
              this.props.userSubmittedQuestionsReport
            }
            isUserSubmittedQuestionsReportLoading={
              this.props.isUserSubmittedQuestionsReportLoading
            }
            setUserSubmittedQuestionsReport={
              this.props.setUserSubmittedQuestionsReport
            }
            /* handleDownloadReport={this.handleDownloadReport} */
          />
        );
      case 15:
        return <IntercomActiveUsersReportContainer />;
      case 16:
        return <TopicsReportContainer />;
      case 17:
        return (
          <RateQuestionsReport
            rates={this.props.rates}
            ratesIsLoading={this.props.ratesIsLoading}
            loadRates={this.props.loadRates}
            generateReportForSelectedRates={
              this.props.generateRateQuestionsReport
            }
            getLastRateQuestionsReports={this.props.getLastRateQuestionsReports}
            lastRateQuestionsReports={this.props.lastRateQuestionsReports}
            /* TODO: */
            rateQuestionsReportIsLoading={false}
          />
        );
      case 18:
        return (
          <UsageStatisticsReport
            generateUsageReport={this.props.generateUsageReport}
            getUsageReports={this.props.getUsageReports}
            usageStatisticsReports={this.props.usageStatisticsReports}
            usageStatisticsReportsIsLoading={
              this.props.usageStatisticsReportsIsLoading
            }
            usageStatisticsReportIsLoading={
              this.props.usageStatisticsReportIsLoading
            }
          />
        );
      default:
        return (
          <div className="whiteButtons" onClick={this.handleDownloadReport}>
            Generate Report
          </div>
        );
    }
  };

  render() {
    const { selectedReport } = this.state;
    const { report = '' } = this.props;

    return (
      <Row className="reports-list-row">
        <Col xs={6} md={3} className="quals-list-container reports-list">
          <div className="rates-list-component">
            <div className="rates-title">
              <div className="flexItemsTitlePlus">Reports</div>
              <div className="line-quals-page" />
            </div>
            <div className="rates-list">
              {REPORTS.map((report) => (
                <div className="row" key={`reports-${report.id}`}>
                  <div
                    className={
                      selectedReport.id === report.id
                        ? 'rate-line active'
                        : 'rate-line'
                    }
                    onClick={() => this.setState({ selectedReport: report })}
                  >
                    <div className="description">{report.name}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Col>
        <Col md={9} style={{ overflow: 'auto', height: '100%' }}>
          {this.props.isLoading ? (
            <Spinner />
          ) : (
            <div style={{ height: '100%' }}>{this.renderReportComponent()}</div>
          )}
        </Col>
        <div style={{ display: 'none' }}>
          <CSVLink
            id="report-download"
            filename={this.state.filename}
            data={report}
          />
        </div>
      </Row>
    );
  }
}

Reports.propTypes = {
  rates: PropTypes.array.isRequired,
  ratesIsLoading: PropTypes.bool.isRequired,
  loadRates: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
  onClearReport: PropTypes.func.isRequired,
  getBibliographyReportForGivenRates: PropTypes.func.isRequired,
  bibliographyReport: PropTypes.array,
  bibliographyReportIsLoading: PropTypes.bool,
  bibliographyReportError: PropTypes.string,
  loadCycles: PropTypes.func.isRequired,
  cycles: PropTypes.array.isRequired,
  profileSheetCsv: PropTypes.array,
  profileSheetIsLoading: PropTypes.bool,
  generateFavoriteQuestionsReport: PropTypes.func.isRequired,
};
