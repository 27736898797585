export const baseURL = process.env.REACT_APP_API_URL;

export const loginUrl = `${baseURL}/adminuser/login`;
export const getUsers = `${baseURL}/user`;
export const admins = `${baseURL}/adminuser`;
export const getAdmins = `${baseURL}/adminuser`;
export const roles = `${baseURL}/role`;
export const getStudyPrograms = `${baseURL}/studyProgram`;
export const getPages = `${baseURL}/pagepermission`;
export const getWorkflowActions = `${baseURL}/workflowaction`;
export const environmentName = process.env.REACT_APP_ENVIRONMENT_NAME;

console.log('env:', process.env.REACT_APP_KEYS_ENV);
